var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments"},[_c('div',{staticClass:"outgoing_payments"},[_c('div',{staticClass:"airfreight_payments"},[_c('b-button',{staticClass:"backbutton mt-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.$router.back()}}},[_c('feather-icon',{staticClass:"text-dark",attrs:{"icon":"ChevronLeftIcon","size":"14"}}),_vm._v(" "),_c('span',{staticClass:"back_button_text_styles"},[_vm._v("Back")])],1),_c('br'),_c('br'),_c('b-card',[_c('center',[_c('b-card-title',{staticClass:"modal_title_color_payment"},[_vm._v("Add payment For "+_vm._s(_vm.$route.params.name)+" Company")])],1),_c('div',{staticClass:"mt-3"}),_c('b-form',{staticClass:"Add_Form",on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"PaymentCreateValidation"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Payment ID / Payment No*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Payment ID / Payment No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Payment ID / Payment No"},model:{value:(_vm.form.payment_no),callback:function ($$v) {_vm.$set(_vm.form, "payment_no", $$v)},expression:"form.payment_no"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Payment Date*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Payment Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"placeholder":"Select Date"},model:{value:(_vm.form.payment_date),callback:function ($$v) {_vm.$set(_vm.form, "payment_date", $$v)},expression:"form.payment_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Payment Currency*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Payment Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.paymentCurrencies},on:{"input":function($event){return _vm.getContinueChecks()}},model:{value:(_vm.paymentcurrency),callback:function ($$v) {_vm.paymentcurrency=$$v},expression:"paymentcurrency"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('span',{staticClass:"repeater_title"},[_vm._v("Bill Number/s*")])]),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6"}},[_c('b-button',{attrs:{"variant":"none"},on:{"click":function($event){return _vm.repeatBill()}}},[_c('span',{staticClass:"repeater_add_buton"},[_vm._v("Add bill")])])],1)],1)],1),_c('div',{staticClass:"mt-3"}),_c('span'),_c('b-col',{attrs:{"lg":"12"}},_vm._l((_vm.bills),function(bill,index){return _c('b-row',{key:bill.id,staticClass:"mr-1",attrs:{"id":bill.id}},[_c('br'),_c('br'),_c('br'),_c('br'),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Bill No*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Bill No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"invoce_no","options":_vm.billnumbers},on:{"input":function($event){return _vm.uniqueShipments(index, bill.billnumber)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[(_vm.paymentcurrency.title === 'LKR')?_c('span',[_vm._v(_vm._s(option.invoice_no)+" - "),_c('b',[_vm._v(" Rs."+_vm._s(option.pending_lkr_cost))])]):_c('span',[_vm._v(_vm._s(option.invoice_no)+" - "),_c('b',[_vm._v(" $"+_vm._s(option.pending_usd_cost))])])])]}},{key:"selected-option",fn:function(option){return [(bill.billnumber === 'Select Invoice')?_c('div',[_vm._v(" "+_vm._s(bill.billnumber)+" ")]):_c('div',[(_vm.paymentcurrency.title === 'LKR')?_c('span',[_vm._v(_vm._s(option.invoice_no)+" - "),_c('b',[_vm._v("Rs."+_vm._s(option.pending_lkr_cost))])]):_c('span',[_vm._v(_vm._s(option.invoice_no)+" - "),_c('b',[_vm._v("$"+_vm._s(option.pending_usd_cost))])])])]}}],null,true),model:{value:(bill.billnumber),callback:function ($$v) {_vm.$set(bill, "billnumber", $$v)},expression:"bill.billnumber"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Payment Status*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.billstatuses},on:{"input":function($event){return _vm.fillAmount(
                              index,
                              bill.status,
                              bill.billnumber.pending_lkr_cost,
                              bill.billnumber.pending_usd_cost,
                              bill.billnumber.airfreight_converting_rate
                            )}},model:{value:(bill.status),callback:function ($$v) {_vm.$set(bill, "status", $$v)},expression:"bill.status"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.paymentcurrency.title === 'USD')?_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Paid Amount*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Amount"},on:{"input":function($event){return _vm.setContinueBalance(
                              index,
                              bill.paid_usd_amount,
                              bill.billnumber.airfreight_converting_rate
                            )}},model:{value:(bill.paid_usd_amount),callback:function ($$v) {_vm.$set(bill, "paid_usd_amount", $$v)},expression:"bill.paid_usd_amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.paymentcurrency.title === 'LKR')?_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Paid Amount*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Amount"},on:{"input":function($event){return _vm.setContinueBalance(
                              index,
                              bill.paid_lkr_amount,
                              bill.billnumber.airfreight_converting_rate
                            )}},model:{value:(bill.paid_lkr_amount),callback:function ($$v) {_vm.$set(bill, "paid_lkr_amount", $$v)},expression:"bill.paid_lkr_amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{staticClass:"minus_button_margin",attrs:{"lg":"1"}},[_c('b-button',{attrs:{"variant":"none"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('b-img',{attrs:{"src":require("@/assets/images/Group.png")}})],1)],1)],1)}),1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Amount(LKR)*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount(LKR)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Amount","readonly":""},model:{value:(_vm.form.lkr_amount),callback:function ($$v) {_vm.$set(_vm.form, "lkr_amount", $$v)},expression:"form.lkr_amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Amount(USD)*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount(USD)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Amount","readonly":""},model:{value:(_vm.form.usd_amount),callback:function ($$v) {_vm.$set(_vm.form, "usd_amount", $$v)},expression:"form.usd_amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.paymentcurrency.title === 'LKR')?_c('b-col',{staticClass:"mt-1",attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Check Number*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Check Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"check_no","options":_vm.airfreightchecks},on:{"input":function($event){return _vm.opencheckmodel()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(
                            option.check_no === 'Add New' ||
                            option.check_no === 'Replace Amount'
                          )?_c('div',{staticClass:"d-center"},[_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v(_vm._s(option.check_no))])]):_c('div',{staticClass:"d-center"},[_c('span',[_vm._v(_vm._s(option.check_no)+" - "),_c('b',[_vm._v(_vm._s(option.amount))])])])]}},{key:"selected-option",fn:function(option){return [(option.check_no)?_c('div',[_vm._v(" "+_vm._s(option.check_no)+" - "),_c('b',[_vm._v(" "+_vm._s(option.amount))])]):_vm._e()]}}],null,true),model:{value:(_vm.checknumber),callback:function ($$v) {_vm.checknumber=$$v},expression:"checknumber"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2672473544)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mt-1",attrs:{"lg":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.finalizeAmount()}}},[_vm._v("Process Full Amount")])],1),_c('b-col',{staticClass:"mt-5 pt-2 text-center",attrs:{"md":"12"}},[_c('b-button',{staticClass:"form_submit_button",attrs:{"type":"submit","variant":"none"},on:{"click":function($event){return _vm.validationPaymentCreateForm()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v(" Create")])])],1)],1)],1)],1)],1)],1),_c('b-modal',{ref:"createcheckmodal",attrs:{"hide-footer":"","title":"Add Check","title-class":"modal_title_color","no-close-on-backdrop":""}},[_c('AirfreightCheckCreate',{attrs:{"propForm":_vm.form},on:{"close":_vm.closeModal}})],1),_c('b-modal',{ref:"checkalert",attrs:{"hide-footer":"","title-class":"modal_title_color","no-close-on-backdrop":""}},[_c('CheckAlert',{on:{"chceckReplaceStatus":_vm.chceckReplaceStatus,"hideCheckeplaceModal":_vm.hideCheckeplaceModal}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }